import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CursosService } from 'src/app/services/cursos.service';
import { UsuarioService } from '../../../../services/usuario.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CdkStepper } from '@angular/cdk/stepper';

// Dialog o modal
import { MatDialog } from '@angular/material/dialog';
import { PagoComponent } from '../pago/pago.component';
import { PagoTarjetaComponent } from '../pago/components/pago-tarjeta/pago-tarjeta.component';
import { PagoMoneComponent } from '../pago/components/pago-mone/pago-mone.component';
import { FreeComponent } from '../pago/components/free/free.component';
import { QrEstaticoComponent } from '../pago/components/qr-estatico/qr-estatico.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-presentacion-curso',
  templateUrl: './presentacion-curso.component.html',
  styleUrls: ['./presentacion-curso.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {
      displayDefaultIndicatorType: false,
      provide: CdkStepper, useExisting: PresentacionCursoComponent
    }
  }],
})

export class PresentacionCursoComponent implements OnInit {

  estado = false;
  misCursos: any = [];
  id: any;
  idD: any;
  curso: any;
  datos: any;
  docente: any;
  usuarioCurso: [];
  modulos: [];
  datosDocente: any;
  mostrarMetodo = false;
  rutaVideo: string;
  amount: any;
  precio: any;

  constructor(public route: ActivatedRoute,
    public serCursos: CursosService,
    public miscursosSrv: UsuarioService,
    public dialog: MatDialog,
    public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.comprobarAuth();
    this.id = this.route.snapshot.params.id;
    this.getData();
  }

  comprobarAuth(): void {
    this.estado = this.miscursosSrv.estadoSession();
    if (this.estado == true) {
      this.misCursosAd();
    }
    //console.log(this.estado);
  }

  misCursosAd(): void {
    this.miscursosSrv.misCursos().subscribe(data => {
      this.misCursos = data;
      //console.log(this.misCursos);
    });
  }

  getData() {
    this.serCursos.presentacionCurso(this.id).subscribe(data => {
      this.datos = data;
      this.curso = this.datos.curso;
      this.precio = this.datos.curso.precio;
      this.docente = this.datos.docente;
      this.datosDocente = this.docente.datos_docente;
      this.modulos = this.datos.modulos;
      this.usuarioCurso = this.datos.usuarioCurso;
      this.idD = this.docente.id_usuario;
      console.log(this.datos);
      console.log(this.precio);
    });
  }

  setCurso() {
    this.serCursos.cursarCurso(this.id).subscribe(data => {
      this.datos = data;
      //console.log(this.datos);
    });
  }

  verVideo(ruta): void {
    this.rutaVideo = ruta;
  }
  setVideo(ruta: string) {
    this.rutaVideo = ruta;
  }

  metodoDeposito() {
    const dialogRef = this.dialog.open(PagoComponent, {
      width: '1800px',
      data: {
        id: this.id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  metodoTarjeta() {
    const dialogRef = this.dialog.open(PagoTarjetaComponent, {
      width: '140vh',
      data: [
        this.precio,
        this.id,
        this.idD,
      ]
    });
    console.log(this.id);
    console.log(this.precio);
    console.log(this.idD);
    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  metodoQr() {
    const dialogRef = this.dialog.open(QrEstaticoComponent, {
      width: '150vh',
      data: {
        id: this.id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  metodoMone() {
    const dialogRef = this.dialog.open(PagoMoneComponent, {
      width: '140vh',
      data: [
        this.id,
        this.idD,
      ]
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  metodoFree() {
    const dialogRef = this.dialog.open(FreeComponent, {
      width: '120vh',
      data: [
        this.id,
        this.idD,
      ]
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  openCompra() {
    const cursoComprado = this.misCursos.find(item => item.id_curso === this.curso.id_curso);
    if (!cursoComprado) {
      this.mostrarMetodo = true;
    } else {
      console.log('El curso ya ha sido comprado.');
    }
  }
  
}
