<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a routerLink="" class="navbar-brand">
    <!-- Academia CoMarca -->
    <img
    src="https://firebasestorage.googleapis.com/v0/b/lms-academia-a582f.appspot.com/o/LMS%2F2_sin_fondo.png?alt=media&token=0f14a34d-4f40-4597-a05e-179ec3375532"
    height="100px" alt="" class="img" />
    <!-- <img src="../../../../assets/img/Logo_2023_fondo.png" height="50px" alt="" /> -->
  </a>

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <!-- MENU -->
    <ul
      *ngFor="let itemMenu of menu"
      class="navbar-nav mr-auto"
      style="height: 100%"
    >
      <!-- MENU DE CATEGORIAS -->
      <li class="nav-item dropdown align-middle">
        <a
          class="btn btn-block btn-hover dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Categorías
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a
            *ngFor="let item of etiqueta"
            class="dropdown-item btn btn-block navbar-hover"
            routerLink="/curso/cursos-categorias/{{ item.id_etiqueta }}"
          >
            {{ item.nombre_etiqueta }}
          </a>
        </div>
      </li>
      <!-- FIN MENU DE CATEGORIAS -->
      <!-- BOTONES DE MENU.JS -->
      <li
        *ngFor="let item of itemMenu.contenido"
        class="nav-item dropdown align-middle"
      >
        <a
          class="btn btn-block btn-hover"
          href="#"
          routerLink="{{ item.link }}"
        >
          {{ item.titulo }}
        </a>
      </li>

      <!-- FIN BOTONES DE MENU.JS -->
      <div class="dropdown-divider"></div>
    </ul>

    <div class="my-2 my-lg-0">
      <ul *ngIf="estado" class="navbar-nav mr-auto">
        <li class="nav-item dropdown">
          <a
            class="btn btn-block btn-hover dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="text-uppercase">
              <img
                class="avatar"
                [src]="datosUsuario.foto_usuario"
                (error)="imagenError()"
              />
              {{ datosUsuario.nombre_usuario }}
            </span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdown"
          >
            <a
              class="dropdown-item btn btn-block navbar-hover"
              routerLink="/estudiante/mi-perfil"
              >Mi Perfil</a
            >
            <a
              class="dropdown-item btn btn-block navbar-hover"
              *ngIf="datosUsuario.id_rol == 1 || datosUsuario.id_rol == 2"
              routerLink="/admin/curso/mis-cursos"
              >Mis Cursos Creados</a
            >
            <a
              class="dropdown-item btn btn-block navbar-hover"
              routerLink="/estudiante/mis-cursos-adquiridos"
            >
              Mis Cursos Adquiridos
            </a>

            <a
              *ngIf="datosUsuario.id_rol != 3"
              class="dropdown-item btn btn-block navbar-hover"
              [routerLink]="
                datosUsuario.id_rol == 1
                  ? 'admin/reportes'
                  : 'admin/cursos/mis-cursos'
              "
            >
              Panel Administrativo
            </a>

            <a
              *ngIf="datosUsuario.id_rol != 3"
              class="dropdown-item btn btn-block navbar-hover"
              routerLink="admin/membresias/docente-membresia"
            >
              Membresias
            </a>

            <a
              class="dropdown-item btn btn-block navbar-hover"
              *ngIf="datosUsuario.id_rol == 3"
              routerLink="/instructores/pasos-instructor"
            >
              Quiero Ser Instructor
            </a>
            <div class="dropdown-divider"></div>
            <a
              class="btn btn-danger btn-block"
              style="color: white"
              (click)="cerrarSesion()"
            >
              Cerrar Sesión
            </a>
          </div>
        </li>
      </ul>
      <!-- SI NADIE ESTA LOGEADO -->
      <ul *ngIf="!estado" class="navbar-nav mr-auto">
        <li class="nav-item align-middle">
          <a
            class="btn btn-block btn-hover"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            routerLink="/instructores/pasos-instructor"
          >
            Sé instructor
          </a>
        </li>

        <li class="nav-item align-middle">
          <a
            class="btn btn-block btn-hover"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            routerLink="/login"
          >
            Iniciar Sesion
          </a>
        </li>

        <li class="nav-item align-middle">
          <a
            class="btn btn-block btn-hover"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            routerLink="/register"
          >
            Regístrate
          </a>
        </li>
      </ul>
      <!-- FIN SI NADIE ESTA LOGEADO -->
    </div>
  </div>
</nav>
