<div class="py-2 container-fluid">
  <!--Progreso de carga del curso-->
  <div *ngIf="!curso" class="text-center">
      <div class="spinner-border" role="status">
          <span class="visually-hidden"></span>
      </div>
  </div>
  <!--Fin de progreso de carga del curso-->
  <div *ngIf="curso">
      <div class="card">
          <div class="bg-white card-header">
              <h2>Curso: {{ curso.nombre_curso }}</h2>
              <h2>Descripción del Curso: {{ curso.descripcion_curso }}</h2>
              <hr />
              <h3 *ngIf="instructor" style="text-align: right">
                  Autor/a del curso:
                  <a routerLink="/instructores/presentacion-docente/{{
            instructor.id_usuario
          }}">{{ instructor.nombre_usuario }}</a
        >
        &nbsp;
        <img
          class="avatar"
          [src]="instructor.foto_usuario"
          fallimg="avatar"
          routerLink="/instructores/presentacion-docente/{{
            instructor.id_usuario
          }}"
        />
      </h3>
    </div>

    <div class="card-body">
      <div class="row">
        <!-- IZQUIERDA -->
        <div class="col-md-6">
          <!--Esta columna incluye:
            * Progreso del curso por parte del estudiante
            * El video de la clase que se seleccione para su reproduccion
            * Recursos (PDFs, Presentaciones y/o doumentos)
            * Foro de comentarios por clase
          -->
          <!--Progress bar de progreso-->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Tu progreso</h4>
              <p class="card-text">
                <mat-progress-bar mode="determinate" [value]="count"
                  >Tu progreso</mat-progress-bar
                >
              </p>
              <div *ngIf="evaluacionButton" mat-line>
                <button
                  class="btn btn-success"
                  type="button"
                  (click)="openEvaluacion(curso.id_curso)"
                >
                  Evaluación
                </button>
              </div>
              <a
                *ngIf="certificadoBoton == true"
                href="{{ API_BACKEND + certificado }}"
                target="_blank"
              >
                <mat-icon>emoji_events</mat-icon> Obtén tu certificado
              </a>
          </div>
      </div>
      <!--Fin Progress bar de progreso-->

      <mat-card class="example-card">
          <!-- Este card contiene:
              * Video de la clase, el titulo y descripcion del curso.
              * Recursos por clase
            -->
          <!-- Este es el video de la clase con una imagen (poster) del curso-->
          <video src="{{ rutaVideo }}" poster="{{ curso.imagen_curso }}" controls muted controlslist="nodownload" autoplay (contextmenu)="displayContextMenu($event); (false)"></video>
          <!-- <img src="{{ curso.imagen_curso }}" alt="Imagen del curso" width="640" height="360"> -->
          <!-- <mat-card-content> -->
          <!--Inicio de la seccion del foro de comentarios-->
          <!--Comentarios-->
          <div class="card">
              <div class="card-header">
                  <h2>Respuestas a preguntas y comentarios</h2>
              </div>
              <div class="card-body" *ngIf="!dat">
                  <div class="alert alert-warning" role="alert">
                      Aqui se cargan los comentarios de cada clase.
                  </div>
              </div>
              <div class="card-body" *ngIf="dat">
                  <mat-selection-list *ngIf="dat" [multiple]="false">
                      <div *ngIf="dat.length == 0" class="alert alert-danger" role="alert">
                          Aún no existen comentarios de la clase. Sé el primero en comentar.
                      </div>
                      <mat-list-item *ngFor="let item of dat" class="row">
                          <div mat-line>
                              <div mat-line>
                                  <div class="name">
                                      <!--<mat-icon color="warn" *ngIf="curso.id_usuario == item.usuario_comentario.id_usuario">verified
                          </mat-icon>-->
                                      <img class="avatar" [src]="item.usuario_comentario.foto_usuario" fallimg="avatar" /> {{ item.usuario_comentario.nombre_usuario }}
                                      <span class="text-white badge bg-danger" *ngIf="
                              curso.id_usuario ==
                              item.usuario_comentario.id_usuario
                            ">Autor</span
                          >
                          <span
                            *ngIf="item.usuario_comentario.id_rol == 1"
                            class="text-white badge bg-dark"
                            >Administrador</span
                          >
                          <span
                            *ngIf="item.usuario_comentario.id_rol == 2"
                            class="badge bg-warning"
                            >Instructor</span
                          >
                          <span
                            *ngIf="item.usuario_comentario.id_rol == 3"
                            class="text-white badge bg-secondary"
                            >Estudiante</span
                          >
                          <mat-card-subtitle class="name">
                            Fecha de publicación:
                            {{ item.created_at | date : "dd/MM/yy" }}
                          </mat-card-subtitle>
                        </div>
                      </div>
                      <!--<div mat-line>{{ item.texto_comentario }}</div>-->
                      <div mat-line>
                        Comentario: {{ item.texto_comentario }}
                      </div>
                      <div align="end" *ngIf="item.id_usuario == usuario">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <!--<button mat-menu-item>
                          <mat-icon>edit</mat-icon>
                          <span>Edita tu comentatio</span>
                                      </button>-->
                                      <button mat-menu-item (click)="borrarComentario(item.id_comentario)">
                            <mat-icon>delete</mat-icon>
                            <span>Elimina tu comentario</span>
                          </button>
                                      </mat-menu>
                                  </div>
                                  <hr />
                                  <div mat-line>
                                      <button mat-button color="warn" (click)="openRespuestas(item.id_comentario)">
                          Responder
                        </button>
                                  </div>
                              </div>
                              <hr />
                              <div mat-line>
                                  <div *ngIf="item.comentario_respuesta.length || 0">
                                      <mat-accordion>
                                          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                              <mat-expansion-panel-header>
                                                  <mat-panel-title>
                                                      Ver {{ item.comentario_respuesta.length }} respuestas
                                                  </mat-panel-title>
                                              </mat-expansion-panel-header>
                                              <mat-list-option *ngFor="
                                let res of item.comentario_respuesta;
                                let i = index
                              ">
                                                  <div mat-line>
                                                      <div mat-line *ngFor="let usuario of est">
                                                          <div *ngIf="
                                      res.id_usuario == usuario.id_usuario
                                    ">
                                                              <img class="avatar" [src]="usuario.estudiante.foto_usuario" fallimg="avatar" /> {{ usuario.estudiante.nombre_usuario }}
                                                              <span class="text-white badge bg-danger" *ngIf="
                                        res.id_usuario == curso.id_usuario
                                      ">Autor</span
                                    >
                                  </div>
                                </div>
                                <div mat-line>
                                  Respuesta: {{ res.texto_respuesta }}
                                  <!--<mat-icon color="warn" mat-list-icon *ngIf="res.id_usuario == curso.id_usuario">
                                    verified</mat-icon>-->
                                </div>
                                <div mat-line>
                                  Fecha de publicación:
                                  {{ res.created_at | date : "dd/MM/yy" }}
                                </div>
                              </div>
                              <div
                                align="end"
                                *ngIf="res.id_usuario == usuario"
                              >
                                <button
                                  mat-icon-button
                                  [matMenuTriggerFor]="menu"
                                >
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                  <!--<button mat-menu-item>
                                    <mat-icon>edit</mat-icon>
                                    <span>Edita tu comentatio</span>
                                                              </button>-->
                                                              <button mat-menu-item (click)="
                                      borrarRespuestaComent(res.id_respuesta)
                                    ">
                                    <mat-icon>delete</mat-icon>
                                    <span>Elimina tu comentario</span>
                                  </button>
                                                              </mat-menu>
                                                          </div>
                                              </mat-list-option>
                                          </mat-expansion-panel>
                                      </mat-accordion>
                                      </div>
                                      </div>
                      </mat-list-item>
                  </mat-selection-list>
                  </div>
                  </div>
                  <!--Comentario del estudiante o docente-->
                  <!-- </mat-card-content> -->
      </mat-card>
      </div>
      <!--FIN IZQUIERDA-->

      <!-- DERECHA -->
      <div class="col-md-6">
          <!-- En esta columna se muestra: -->
          <!-- * Secciones del Curso -->
          <!-- * Clases del Curso -->
          <!-- * Un boton para mostrar los recurso -->

          <!--LISTADO DE LINKS DE SESIONES -->
          <div class="card" *ngIf="sesiones">
              <div class="card-header">
                  <h4 class="card-title">Sesiones de clases en vivo</h4>
              </div>
              <div class="card-body">
                  <div class="card-text">
                      <div class="row">
                          <div class="card col-lg-6 col-md-6 col-sm-12" *ngFor="let sesion of sesiones; let i = index">
                              <div class="card-header">Sesión {{ i + 1 }}</div>
                              <div class="card-body">
                                  <h5 class="card-title">
                                      {{ sesion.fecha_transmision_sesion | date : "medium" }}
                                  </h5>
                                  <p class="card-text">{{ sesion.descripcion_sesion }}</p>
                                  <a href="{{ sesion.link_sesion }}" target="”_blank”" class="btn btn-success btn-sm btn-block">Unirme...!</a
                      >
                      <a
                        href="{{ sesion.video_sesion }}"
                        target="”_blank”"
                        *ngIf="sesion.video_sesion"
                        ><span><i class="fa fa-user"></i></span
                      ></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!--FIN LISTADO DE LINKS DE SESIONES -->

          <!-- LISTADO DE MODULOS -->
          <div id="accordion">
              <div class="card" *ngFor="let modulo of modulos; let i = index; first as isFirst">
                  <div class="input-group" id="{{ 'heading' + i }}">
                      <div class="input-group-prepend">
                          <div class="input-group-text">
                              <input [(ngModel)]="progreso[i].estado" (change)="cambios(i, !progreso[i].estado)" type="checkbox" aria-label="Checkbox for following text input" matTooltip="Sección terminada!" />
                          </div>
                      </div>
                      <div class="form-control btn btn-primary" data-toggle="collapse" [attr.data-target]="'#collapse' + i" aria-expanded="false" aria-controls="{{ 'collapse' + i }}">
                          <h3 class="mb-0">
                              Módulo {{ i + 1 }}: &nbsp;{{ modulo.nombre_modulo }}
                          </h3>
                      </div>
                  </div>
                  <!-- <div #shoes [multiple]="false"> -->
                  <div *ngFor="
                  let clases of modulo.clases_modulo;
                  let j = index;
                  let prog = pro
                " id="{{ 'collapse' + i }}" class="collapse" aria-labelledby="{{ 'heading' + i }}" data-parent="#accordion">
                      <div class="card-body">
                          <div class="list-group">
                              <button (click)="
                        setVideo(
                          clases.video_clase,
                          clases.id_clase,
                          clases.id_clase
                        )
                      " class="list-group-item list-group-item-action">
                      <span
                        ><i
                          style="color: red; font-size: 20px"
                          class="fa fa-play-circle"
                        ></i
                      ></span>
                      Clase {{ j + 1 }}: {{ clases.titulo_clase }}
                    </button>
                              <div class="list-group-item">
                                  <h3>Descripción de la clase:</h3>
                                  <p>{{ clases.descripcion_clase }}</p>
                              </div>
                          </div>
                      </div>
                      <div class="card-footer">
                          <div class="row">
                              <div class="col-lg-4 col-md-4 col-sm-12">
                                  <button (click)="getClase(clases.id_clase)" class="btn btn-primary btn-block" type="button" data-toggle="collapse" [attr.data-target]="
                          '#collapseRecursos' + clases.id_clase
                        " aria-expanded="false" aria-controls="collapseExample">
                        <span
                          ><i class="fa fa-archive"></i> Ver recursos</span
                        >
                      </button>
                                  <!-- <button (click)="getClase(clases.id_clase)" class="btn btn-primary btn-block" type="button">
                          Ver recursos
                        </button> -->
                                  <a class="btn btn-success btn-block" (click)="openDialog(clases.id_clase)">
                                      <span><i class="fa fa-commenting"></i> Comentar</span>
                                  </a>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-12">
                                <div class="collapse" id="{{ 'collapseRecursos' + clases.id_clase }}" [class.show]="currentOpenId === clases.id_clase">  
                                      <div class="card">
                                          <div class="card-body">
                                              <h4 class="card-title">Recursos de la clase:</h4>
                                              <div class="list-group" *ngIf="recursos">
                                                  <a *ngFor="let recurso of recursos" href="{{ recurso.link_recurso }}" target="”_blank”" class="list-group-item list-group-item-action">
                                                      <span><i class="fa fa-download"></i
                                  >{{ recurso.nombre_recurso }}</span
                                >
                              </a>
                            </div>
                            <div
                              *ngIf="recursos == ''"
                              class="alert alert-warning"
                              role="alert"
                            >
                              Esta clase no tiene recursos cargados.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </div>
          </div>
          <!-- FIN LISTADO DE MODULOS -->
        </div>
        <!-- FIN DERECHA -->
      </div>
    </div>
  </div>
</div>
</div>