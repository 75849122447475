<div *ngIf="!datos" class="row my-5">
  <div class="alert text-center col-12 my-5">
    <!-- <h2>Codigo introducido no pertenece a ninguno de nuestros alumnos.</h2> -->
  </div>
</div>
<div *ngIf="datos" class="container register">
  <div class="row">
    <div class="col-md-3 register-left">
      <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt="" />
      <h3>Bienvenido</h3>
      <p>
        Verificamos si el codigo QR escaneado pertenece a uno de nuestros
        alumnos. Informarle que el certificado es {{ datos.estado }}.
      </p>
      <p *ngIf="datos.estado == 'VALIDO'">
        El certificado tiene los siguientes datos.
      </p>
      <div class="row">
        <input
          type="text"
          placeholder="Buscar certificado"
          [(ngModel)]="search"
          class="form-control col-12"
        />
      </div>
      <button
        type="button"
        class="btn btn-default btn-block"
        (click)="btnClick()"
      >
        Buscar
      </button>
    </div>
    <div class="col-md-9 register-right">
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <h3 class="register-heading">DATOS DEL CERTIFICADO</h3>
          <div class="row register-form">
            <div class="col-md-6">
              <div class="form-group">
                <label>Nombre de Estudiante</label>
                <input
                  type="text"
                  class="form-control disabled-input"
                  [(ngModel)]="user"
                  [disabled]="isDisabled"
                />
              </div>
              <div class="form-group">
                <label>Foto Del Estudiante</label>
                <div class="text-center">
                  <img
                    src="{{ datos.foto_usuario }}"
                    style="border-radius: 25%"
                    width="200px"
                    height="200px"
                    alt="Foto Del Estudiante"
                  />
                  <div class="h-30"></div>
                </div>
              </div>
              <div class="form-group">
                <label>Fecha de obtencion del certificado</label>
                <input
                  type="text"
                  class="form-control disabled-input"
                  [(ngModel)]="fecha_culminacion"
                  [disabled]="isDisabled"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Nombre Del Docente</label>
                <input
                  type="text"
                  class="form-control disabled-input"
                  [(ngModel)]="docente"
                  [disabled]="isDisabled"
                />
              </div>
              <div class="form-group">
                <label>Foto Del Docente</label>
                <div class="text-center">
                  <img
                    src="{{ datos.foto_docente }}"
                    style="border-radius: 25%"
                    width="200px"
                    height="200px"
                    alt="Foto Del Docente"
                  />
                  <div class="h-30"></div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Curso vencido</label>
                <textarea
                  rows="3"
                  class="form-control disabled-input text-center"
                  [(ngModel)]="curso"
                  [disabled]="isDisabled"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
