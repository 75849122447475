import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CursosService } from 'src/app/services/cursos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.scss']
})
export class CertificadoComponent implements OnInit {
  code: string;
  datos: any;
  search: string;

  user: string;
  fecha_culminacion: any;
  docente: string;
  curso: string;
  isDisabled: boolean = true;

  toggleDisable() {
    this.isDisabled = !this.isDisabled;
  }

  constructor(private route: ActivatedRoute, public serCursos: CursosService, private router: Router) { }

  ngOnInit(): void {
    this.code = this.route.snapshot.params.code;
    this.getData();
  }

  getData() {
    this.serCursos.verificarCertificado(this.code).subscribe(data => {
      this.datos = data;
      console.log('Datos');
      console.log(this.datos);
      this.user = this.datos.user;
      this.fecha_culminacion = this.datos.fecha_culminacion;
      this.docente = this.datos.docente;
      this.curso = this.datos.curso;
    });
  }

  btnClick= function () {
    this.router.navigateByUrl('/certificado/' + this.search);
    this.code = this.search;
    this.getData();
  };

}
