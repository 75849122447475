export const MENU = [
  {
    contenido: [
      {
        titulo: 'Cursos',
        link: '/curso/cursos-general'
      },
      {
        titulo: 'Instructores',
        link: '/instructores/listado-instructores'
      },
      {
        titulo: 'Nosotros',
        link: '/info/nosotros'
      },
      {
        titulo: 'Certificado',
        link: '/certificado'
      }
    ]
  }
];