import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CursosService } from 'src/app/services/cursos.service';
import { EvaluacionService } from 'src/app/services/evaluacion.service';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ComentariosCursoComponent } from './comentarios-curso/comentarios-curso.component';
import { RespuestasCursoComponent } from './respuestas-curso/respuestas-curso.component';
import { EvaluacionCursoComponent } from './evaluacion-curso/evaluacion-curso.component';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-curso',
  templateUrl: './curso.component.html',
  styleUrls: ['./curso.component.scss'],
  providers: [
    CursosService
  ]
})
export class CursoComponent implements OnInit {

  API_BACKEND = environment.urlBackend + 'certificado/';

  id: any;
  clasesId: any;
  datos: any;
  curso: any;
  modulos: any;
  certificado: any;
  estado = true;
  rutaVideo: string;
  recursos: any;
  count: any;
  progreso: any;
  formRegistrarComentario: any;
  dat: any;
  certificadoBoton = false;
  evaluacionButton = false;
  evaluacion: any;
  idCurso: any;
  usuario: any;
  coment: any;
  sesiones: any;
  instructor: any;
  est: any;
  nota_minima: any;
  currentOpenId: string = null;

  constructor(
    public serCursos: CursosService,
    public miscursosSrv: UsuarioService,
    public postEvaluacion: EvaluacionService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.id;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.getData();
  }

  getData() {
    this.serCursos.cursarCurso(this.id).subscribe(data => {
      this.datos = data;
      this.curso = this.datos.curso;
      this.modulos = this.datos.modulos;
      this.progreso = JSON.parse(this.datos.cursoUsuario.progreso_curso);
      this.certificado = this.datos.cursoUsuario.id_usuario_curso;
      this.sesiones = this.datos.sesiones;
      this.evaluacion = this.datos.evaluacion;
      this.usuario = JSON.parse(this.datos.cursoUsuario.id_usuario);
      this.instructor = this.datos.instructor;
      this.est = this.datos.estudiantes;
      this.nota_minima = this.datos.curso.nota_minima;
      this.estado = false;
      this.progresoBarra();
    });
  }

  cambios(index, valor: { index; valor: boolean; }) {
    console.log(index, valor);
    this.progreso[index].estado = !valor;
    console.log(this.progreso);
    this.progresoBarra();
  }

  progresoBarra() {
    const conteo = this.progreso.filter(res => res.estado === true);
    this.count = (conteo.length * 100) / this.progreso.length;
  
    if (this.count == 100) {
      this.evaluacionButton = true;
      if (this.evaluacion.progreso_evaluacion > this.nota_minima) {
        this.certificadoBoton = true;
        this.evaluacionButton = false;
      } else {
        this.certificadoBoton = false;
      }
    } else {
      this.evaluacionButton = false;
      this.certificadoBoton = false;
    }
  
    localStorage.setItem('progreso', JSON.stringify(this.progreso));
  
    const progres = {
      'progreso_curso': JSON.stringify(this.progreso),
    };
    this.serCursos.progresoCurso(progres, this.id).subscribe(res => {
      // console.log(res);
    }, error => console.log(error));
  }

  // esta funcion abre y muestra los recursos de la clase
  getClase(identificador: string) {
    this.serCursos.presentacionClase(identificador).subscribe(data => {
      this.datos = data;
      this.recursos = this.datos.recursos;
      if (this.recursos.length !== 0) {
        this.estado = false;
      }
      if (this.currentOpenId === identificador) {
        // Si se hace clic en el mismo elemento que está abierto, ciérralo
        this.currentOpenId = null;
      } else {
        // Si se hace clic en un elemento diferente, actualiza el ID del elemento actualmente abierto
        this.currentOpenId = identificador;
      }
    });
  }

  buildForm(): void {
    this.formRegistrarComentario = this.formBuilder.group({
      texto_comentario: ['', Validators.required]
    });
  }

  //obtengo el id de la clase
  getIdClase(idClase: any) {
    this.clasesId = idClase;
    this.openComentarios(idClase);
  }

  //muestra los comentarios por clase
  openComentarios(comments) {
    this.serCursos.mostrarComentario(comments).subscribe(res => {
      this.dat = res;
    });
  }

  borrarComentario(id: number): void {
    Swal.fire({
      title: '¿Desea eliminar el comentario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, deseo eliminar',
      cancelButtonText: 'No, cancelar!',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.serCursos.eliminarComentario(id).subscribe(res => {
          console.log(res);
          this.coment = res;
          Swal.fire({
            title: this.coment.mensaje,
            icon: this.coment.estado
          });
          this.openComentarios(this.clasesId);
        });
      }
    });
  }

  borrarRespuestaComent(id: number): void {
    Swal.fire({
      title: '¿Desea eliminar la respuesta del comentario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, deseo eliminar',
      cancelButtonText: 'No, cancelar!',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.serCursos.eliminarRespuesta(id).subscribe(res => {
          console.log(res);
          this.coment = res;
          Swal.fire({
            title: this.coment.mensaje,
            icon: this.coment.estado
          });
          this.openComentarios(this.clasesId);
        });
      }
    });
  }


  openDialog(idC: number): void {
    const dialogRef = this.dialog.open(ComentariosCursoComponent, {
      data: idC,
      width: '100vh'
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.openComentarios(idC);
    });
  }

  openRespuestas(idR: number): void {
    const dialogRef = this.dialog.open(RespuestasCursoComponent, {
      data: idR,
      width: '100vh'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.openComentarios(this.clasesId);
    });
  }

  openEvaluacion(idCurso: number): void {
    this.idCurso = idCurso;
    const dialogRef = this.dialog.open(EvaluacionCursoComponent, {
      data: idCurso,
      disableClose: true,
      width: '500vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.getData();
    });
  }

  //carga de comentarios
  cargarDatos(): void {
    this.serCursos.comentario().subscribe(res => {
      this.dat = res;
      console.log(this.dat);
    }, error => {
      //console.log(error);
    });
  }

  changed() {
    this.count = 0;
    this.modulos.forEach(item => {
      if (item.checked) {
        this.count = this.count + 1;
        //console.log('changed', this.count);
      }
    });
  }

  //, idClase: any
  setVideo(ruta: string, idClase: any) {
    this.rutaVideo = ruta;
    this.clasesId = idClase;
    this.getClase(idClase);
    this.openComentarios(idClase);
  }

  /*ngOnDestroy() {
    if (this.progreso != null) {
      const progres = {
        'progreso_curso': JSON.stringify(this.progreso),
      };
      //console.log(progres);
      this.serCursos.progresoCurso(progres, this.id).subscribe(res => {
        //console.log(res);
      }, error => console.log(error));
    }
  }*/

  verCertificado() {
    //console.log(this.id);
    this.serCursos.certificado(this.id).subscribe(res => {
      //console.log(res);
    });
  }
}
