import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output, NgModule } from '@angular/core';
import { UsuarioService } from '../../../services/usuario.service';
import { AuthService } from '../../../services/auth.service';
import { LoginService } from '../../../services/login.service';
import { EtiquetaService } from '../../../services/etiqueta.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { CursosService } from '../../../services/cursos.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MENU } from './menu';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  @Input() deviceXs: boolean;
  respuesta: any;
  mobileQuery: MediaQueryList;
  estado: boolean;
  datosUsuario: any;
  etiqueta: any;
  cursos = [];
  menu = MENU;

  private mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private loginSrv: LoginService,
    private authSer: AuthService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private usuarioService: UsuarioService,
    public serCursos: CursosService,
    private etiquetaService: EtiquetaService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px) and (max-width: 959px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    this.comprobarAuth();
  }

  ngOnInit(): void {
    this.getData();
    this.cargarEtiquetas();
  }

  getData() {
    this.serCursos.listarCursos().subscribe((data: any[]) => this.cursos = data);
    console.log(this.cursos);
  }

  imagenError(): void {
    this.datosUsuario.foto_usuario = 'https://ui-avatars.com/api/?background=random&name=' + this.datosUsuario.nombre_usuario;
  }

  comprobarAuth(): void {
    this.estado = this.usuarioService.estadoSession();
    if (this.estado) {
      this.datosUsuario = localStorage.getItem('datosUsuario');
      this.datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));
    }
    //console.log(this.estado);
  }
  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  cerrarSesion(): void {
    this.loginSrv.logoutUsuario().subscribe((res) => {
      console.log(res);
      localStorage.removeItem('datosUsuario');
      localStorage.removeItem('token');
      this.authSer.logout();
      window.location.reload();
      this.router.navigateByUrl('/');
      this.respuesta = res;
      this.openSnackBar(this.respuesta.mensaje, 'cerrar');
    });
  }

  cargarEtiquetas() {
    this.etiquetaService.listarEtiquetas().subscribe(data => {
      this.etiqueta = data;
    });
  }
}

